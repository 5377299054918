import React, { useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@material-ui/core';
import ButtonWithCircularProgress from 'components/ButtonWithCircularProgress';
import MeetingForm, { MeetingFormData } from './MeetingForm';
import * as R from 'ramda';
import AppEditor from 'components/Editor';
import { useRespondToMeetingRequestMutation, useMemberRequestQuery } from 'generated/graphql';
import { formatName } from 'utils/formatName';

const Meeting = () => {
    const { token } = useParams();
    const [meetingResponses, setMeetingResponses] = useState<Array<MeetingFormData>>([]);
    const [step, setStep] = useState(0);
    const [respondToMeetingRequest, { loading: loadingMutation }] = useRespondToMeetingRequestMutation({
        onError: error => {
            console.log(error);
        },
        onCompleted: _ => {
            setStep(2);
        }
    });

    const { data, loading } = useMemberRequestQuery({
        variables: { token },
        onCompleted: data => {
            const responses: Array<MeetingFormData> = data.memberRequest.requestMeetings.map(requestMeeting => {
                const { lastResponse } = requestMeeting;

                return {
                    meetingId: requestMeeting.meeting.id,
                    accepted: lastResponse ? lastResponse.accepted : false,
                    eveHotel: lastResponse ? lastResponse.eveHotel : false,
                    noKmsFees: lastResponse ? lastResponse.noKmsFees : false,
                    freeHosting: lastResponse ? lastResponse.freeHosting : false,
                    informations: lastResponse ? lastResponse.informations : '',
                    meetingDates: requestMeeting.meeting.meetingDates.map(meetingDate => {
                        const responseDate = lastResponse && lastResponse.meetingDateResponses.find(r => r.meetingDate.id === meetingDate.id);

                        return {
                            meetingDateId: meetingDate.id,
                            accepted: responseDate ? true : false,
                            lunch: responseDate ? responseDate.lunch : false,
                            dinner: responseDate ? responseDate.dinner : false,
                            hotel: responseDate ? responseDate.hotel : false,
                            informations: responseDate ? responseDate.informations : '',
                        };
                    })
                };
            });
            setMeetingResponses(responses);
        }
    });

    if (loading || !data)
        return null;

    const handleSubmit = () => {
        respondToMeetingRequest({
            variables: {
                input: {
                    token: token,
                    responses: meetingResponses.map(meetingResponse => ({
                        ...meetingResponse,
                        meetingDates: meetingResponse.meetingDates.filter(e => e.accepted).map(e => R.omit(['accepted'], e))
                    }))
                }
            }
        });
    };

    const responded = data.memberRequest.responded || data.memberRequest.requestMeetings.length === 0;

    const getStepContent = () => {
        switch (step) {
            case 0:
                return (
                    <Fragment>
                        <Box marginBottom={2}>
                            <AppEditor initialValue={JSON.parse(data.memberRequest.message.message)} readOnly />
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Button variant="contained" color="primary" onClick={() => setStep(1)}>Commencer</Button>
                        </Box>
                    </Fragment>
                );
            case 1:
                return (
                    <Fragment>
                        <Box>
                            {data.memberRequest.requestMeetings.map((requestMeeting, indexMeeting) =>
                                <MeetingForm key={requestMeeting.id} requestMeeting={requestMeeting} indexMeeting={indexMeeting}
                                    meetingResponses={meetingResponses} setMeetingResponses={setMeetingResponses} meetingMemberRoute={requestMeeting.meetingMemberRoute} />
                            )}
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <ButtonWithCircularProgress label="Valider" loading={loadingMutation} disabled={!canSubmit() || loadingMutation}
                                onClick={handleSubmit} />
                        </Box>
                    </Fragment>
                );
            case 2:
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography>Vos réponses ont bien été enregistrés.</Typography>
                    </Box>
                );
            default:
                return null;
        }
    };

    const canSubmit = () => {
        if (meetingResponses === null)
            return false;

        for (const meetingResponse of meetingResponses) {
            if (meetingResponse.accepted && meetingResponse.meetingDates.filter(meetingDate => meetingDate.accepted).length === 0)
                return false;
        }

        return true;
    };

    return (
        <Container maxWidth="md">
            <Box margin={2}>
                <Typography variant="h5" align="center">Appel à candidature - {formatName(data.memberRequest.member)}</Typography>
            </Box>
            {!responded && getStepContent()}
            {responded &&
                <Box display="flex" justifyContent="center">
                    <Typography>Vous avez déjà répondu</Typography>
                </Box>
            }
        </Container>
    );
};

export default Meeting;
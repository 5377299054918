import React from 'react';
import { Box, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/browser';

type ErrorBoundaryState = {
    hasError: boolean
}

class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {

    state: ErrorBoundaryState = {
        hasError: false
    };

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        Sentry.captureException(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Box padding={4} marginTop={8}>
                    <Typography variant="h4" align="center">Une erreur est survenue.</Typography>
                </Box>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
import React, { useState, lazy, Suspense } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Switch, Route } from 'react-router-dom';
import { UserContext } from 'context';
import Cookies from 'universal-cookie';
import { LinearProgress } from '@material-ui/core';
import TopBar from './components/TopBar';
import NavBar from './components/NavBar';
import Home from './scenes/Home';
import Account from './scenes/Account';
import Logout from './scenes/Logout';
import ErrorBoundary from 'components/ErrorBoundary';
import { useCurrentUserQuery, useLogoutMutation } from 'generated/graphql';

const Invitation = lazy(() => import('scenes/UnloggedApp/scenes/Invitation'));
const Matches = lazy(() => import('./scenes/Matches'));
const Referees = lazy(() => import('./scenes/Referees'));
const RefereesStats = lazy(() => import('./scenes/RefereesStats'));
const Volunteers = lazy(() => import('./scenes/Volunteers'));
const Trainings = lazy(() => import('./scenes/Trainings'));
const Trainers = lazy(() => import('./scenes/Trainers'));
const TrainersStats = lazy(() => import('./scenes/TrainersStats'));
const Trainees = lazy(() => import('./scenes/Trainees'));
const Meetings = lazy(() => import('./scenes/Meetings'));
const Members = lazy(() => import('./scenes/Members'));
const MembersStats = lazy(() => import('./scenes/MembersStats'));
const Admins = lazy(() => import('./scenes/Admins'));
const Users = lazy(() => import('./scenes/Users'));
const LeagueSettings = lazy(() => import('./scenes/LeagueSettings'));

const cookies = new Cookies();

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    container: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    navBar: {
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: '0 0 auto'
    },
    content: {
        overflowY: 'auto',
        flex: '1 1 auto'
    }
}));

const MainApp = () => {
    const classes = useStyles();
    const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
    const { data, error, loading } = useCurrentUserQuery({
        onError: () => logout()
    });
    const [logout] = useLogoutMutation({
        onCompleted: () => {
            window.location.reload();
        },
        onError: () => {
            if (process.env.NODE_ENV === 'production') {
                cookies.remove('id', { httpOnly: false, domain: process.env.REACT_APP_COOKIES_DOMAIN });
            }
            if (process.env.NODE_ENV === 'development') {
                cookies.remove('id', { httpOnly: false });
            }
            window.location.reload();
        }
    });

    if (loading)
        return (
            <div className={classes.root}>
                <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} user={null} />
                <LinearProgress />
            </div>
        );

    if (error) {
        return <div>`Error! ${error.message}`</div>;
    }

    if (!data?.user)
        return null;

    const user = data.user;

    return (
        <UserContext.Provider value={data.user}>
            <div className={classes.root}>
                <TopBar user={user} onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
                <div className={classes.container}>
                    <NavBar user={user} onMobileClose={() => setOpenNavBarMobile(false)} openMobile={openNavBarMobile} />
                    <div className={classes.content}>
                        <Suspense fallback={<LinearProgress />}>
                            <Switch>
                                <Route exact path='/'>
                                    <Home />
                                </Route>
                                <Route exact path='/logout'>
                                    <Logout />
                                </Route>
                                <Route exact path='/account'>
                                    <ErrorBoundary key="1">
                                        <Account />
                                    </ErrorBoundary>
                                </Route>
                                {(user.admin || user.permissions.includes('match_read')) &&
                                    <Route path='/matches'>
                                        <ErrorBoundary key="2">
                                            <Matches />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('match_read')) &&
                                    <Route path='/referees'>
                                        <ErrorBoundary key="3">
                                            <Referees />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('match_read')) &&
                                    <Route path='/refereesStats'>
                                        <ErrorBoundary key="3a">
                                            <RefereesStats />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('match_read')) &&
                                    <Route path='/volunteers'>
                                        <ErrorBoundary key="4">
                                            <Volunteers />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('training_read')) &&
                                    <Route path='/trainings'>
                                        <ErrorBoundary key="5">
                                            <Trainings />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('training_read')) &&
                                    <Route path='/trainers'>
                                        <ErrorBoundary key="6">
                                            <Trainers />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('training_read')) &&
                                    <Route path='/trainersStats'>
                                        <ErrorBoundary key="6a">
                                            <TrainersStats />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('training_read')) &&
                                    <Route path='/trainees'>
                                        <ErrorBoundary key="7">
                                            <Trainees />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('meeting_read')) &&
                                    <Route path='/meetings'>
                                        <ErrorBoundary key="8">
                                            <Meetings />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('meeting_read')) &&
                                    <Route path='/members'>
                                        <ErrorBoundary key="9">
                                            <Members />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {(user.admin || user.permissions.includes('meeting_read')) &&
                                    <Route path='/membersStats'>
                                        <ErrorBoundary key="9a">
                                            <MembersStats />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {user.admin && !user.department &&
                                    <Route exact path='/league'>
                                        <ErrorBoundary key="10">
                                            <LeagueSettings />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {user.admin && !user.department &&
                                    <Route path='/admins'>
                                        <ErrorBoundary key="11">
                                            <Admins />
                                        </ErrorBoundary>
                                    </Route>
                                }
                                {user.admin &&
                                    <Route path='/users'>
                                        <ErrorBoundary key="12">
                                            <Users />
                                        </ErrorBoundary>
                                    </Route>
                                }
                            </Switch>
                        </Suspense>
                    </div>
                </div>
            </div>
        </UserContext.Provider>
    );
};

const LoggedApp = () => {

    return (
        <Suspense fallback={<LinearProgress />}>
            <Switch>
                <Route path='/invitation'>
                    <ErrorBoundary key="l1">
                        <Invitation />
                    </ErrorBoundary>
                </Route>
                <Route path='/'>
                    <ErrorBoundary key="l2">
                        <MainApp />
                    </ErrorBoundary>
                </Route>
            </Switch>
        </Suspense>
    );
};

export default LoggedApp;
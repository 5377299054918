import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './normalize.css';
import App from './App';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import { Settings } from 'luxon';
import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import theme from './theme';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import cache from './utils/cache';
import { LastLocationProvider } from 'react-router-last-location';
import * as Sentry from '@sentry/browser';
import { LoggedInProvider } from 'context';

Sentry.init({ dsn: "https://803c59542a864427955ee5426fe1d763@o376094.ingest.sentry.io/5196483" });

if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React);
}

Settings.defaultZoneName = 'Europe/Paris';
Settings.defaultLocale = 'fr';

const client = new ApolloClient({
    link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                graphQLErrors.forEach(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                    ),
                );
            if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
        new HttpLink({
            uri: process.env.REACT_APP_REMOTE_API,
            credentials: 'include'
        })]),
    cache: cache,
    resolvers: {},
    assumeImmutableResults: true
});

ReactDOM.render(
    <BrowserRouter>
        <LastLocationProvider>
            <ApolloProvider client={client}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider autoHideDuration={3500}>
                        <MuiPickersUtilsProvider utils={LuxonUtils} locale={"fr"}>
                            <LoggedInProvider>
                                <App />
                            </LoggedInProvider>
                        </MuiPickersUtilsProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </ApolloProvider>
        </LastLocationProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
import React from 'react';
import { Card, Typography, Collapse, Box, FormControlLabel, Switch, TextField, Checkbox } from '@material-ui/core';
import MeetingDateForm, { MeetingDateFormData } from './MeetingDateForm';
import KmFees from './KmFees';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { MeetingMemberRoute, Maybe, MeetingDate, MeetingResponse, MeetingDateResponse, MemberRequestMeeting, Meeting, LeaguePrices } from 'generated/graphql';
import AppEditor from 'components/Editor';

type MeetingFormData = {
    meetingId: string
    accepted: boolean
    eveHotel: boolean
    noKmsFees: boolean
    freeHosting: boolean
    informations: string
    meetingDates: Array<MeetingDateFormData>
}

type Props = {
    requestMeeting: (
        Pick<MemberRequestMeeting, 'id'>
        & {
            meeting: (
                Pick<Meeting, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
                & { prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm'>>, meetingDates: Array<Pick<MeetingDate, 'date' | 'id' | 'lunch' | 'startHour' | 'hotel' | 'endHour' | 'dinner'>> }
            ), meetingMemberRoute: Pick<MeetingMemberRoute, 'id' | 'from' | 'to'>, lastResponse?: Maybe<(
                Pick<MeetingResponse, 'accepted' | 'createdAt' | 'eveHotel' | 'id' | 'informations'>
                & {
                    meetingDateResponses: Array<(
                        Pick<MeetingDateResponse, 'dinner' | 'hotel' | 'id' | 'informations' | 'lunch'>
                        & { meetingDate: Pick<MeetingDate, 'id'> }
                    )>
                }
            )>
        }
    )
    meetingResponses: Array<MeetingFormData>
    setMeetingResponses: React.Dispatch<React.SetStateAction<MeetingFormData[]>>
    indexMeeting: number
    meetingMemberRoute: Pick<MeetingMemberRoute, 'id' | 'from' | 'to'>
}
const MeetingForm = ({ requestMeeting, meetingResponses, setMeetingResponses, indexMeeting, meetingMemberRoute }: Props) => {
    const { meeting } = requestMeeting;
    const meetingResponse = meetingResponses[indexMeeting];

    const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const lens = R.lensPath([indexMeeting, name]);
        setMeetingResponses(R.set(lens, e.target.value, meetingResponses));
    }

    const handleChangeChecked = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const lens = R.lensPath([indexMeeting, name]);
        setMeetingResponses(R.set(lens, e.target.checked, meetingResponses));
    }

    return (
        <Box marginBottom={3}>
            <Card>
                <Box padding={2}>
                    <Typography variant="h6">
                        {meeting.title}
                    </Typography>
                    <Typography variant="body1">
                        {`Du ${DateTime.fromSQL(meeting.startDate).toLocaleString(DateTime.DATE_MED)} au ${DateTime.fromSQL(meeting.endDate).toLocaleString(DateTime.DATE_MED)}`}
                    </Typography>
                    <Typography variant="body1">
                        {`${meeting.address} ${meeting.zipCode} ${meeting.city}`}
                    </Typography>
                    <AppEditor initialValue={JSON.parse(meeting.informations)} readOnly />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={meetingResponse.accepted}
                                onChange={handleChangeChecked('accepted')}
                                color="primary"
                            />
                        }
                        label="Je suis disponible pour participer"
                    />
                    <Collapse in={meetingResponse.accepted} timeout="auto">
                        <Box paddingX={2}>
                            <KmFees prices={meeting.prices} meetingResponse={meetingResponse} meetingMemberRoute={meetingMemberRoute} />
                            <TextField fullWidth label="Informations" value={meetingResponse.informations} onChange={handleChange('informations')} />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={meetingResponse.eveHotel} disabled={!meeting.eveHotel}
                                        onChange={handleChangeChecked('eveHotel')} />
                                }
                                label="Hotel la veille"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={meetingResponse.noKmsFees}
                                        onChange={handleChangeChecked('noKmsFees')} />
                                }
                                label="Sans frais kilométriques"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={meetingResponse.freeHosting}
                                        onChange={handleChangeChecked('freeHosting')} />
                                }
                                label="Héberger gratuitement"
                            />
                            {meeting.meetingDates.map((meetingDate, indexMeetingDate) => <MeetingDateForm key={meetingDate.id} meetingDate={meetingDate}
                                meetingResponses={meetingResponses} setMeetingResponses={setMeetingResponses} indexMeeting={indexMeeting} indexMeetingDate={indexMeetingDate} />)}
                        </Box>
                    </Collapse>
                </Box>
            </Card>
        </Box>
    );
};

MeetingForm.whyDidYouRender = true

export default MeetingForm;
export type { MeetingFormData };
import React from 'react';
import { Card, Typography, Collapse, Box, FormControlLabel, Switch, TextField, Checkbox } from '@material-ui/core';
import MatchDateForm, { MatchDateFormData } from './MatchDateForm';
import KmFees from './KmFees';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import { RefereeRequestMatch, Match, MatchDate, MatchRefereeRoute, MatchResponse, MatchDateResponse, Maybe, LeaguePrices } from 'generated/graphql';
import AppEditor from 'components/Editor';

type MatchFormData = {
    matchId: string
    accepted: boolean
    eveHotel: boolean
    noKmsFees: boolean
    freeHosting: boolean
    informations: string
    matchDates: Array<MatchDateFormData>
}

type Props = {
    requestMatch: (
        Pick<RefereeRequestMatch, 'id'>
        & {
            match: (
                Pick<Match, 'address' | 'city' | 'endDate' | 'eveHotel' | 'id' | 'level' | 'startDate' | 'title' | 'zipCode' | 'lat' | 'lng' | 'informations'>
                & { prices?: Maybe<Pick<LeaguePrices, 'id' | 'pricePerKm'>>, matchDates: Array<Pick<MatchDate, 'date' | 'id' | 'lunch' | 'startHour' | 'hotel' | 'endHour' | 'dinner'>> }
            ), matchRefereeRoute: Pick<MatchRefereeRoute, 'id' | 'from' | 'to'>, lastResponse?: Maybe<(
                Pick<MatchResponse, 'accepted' | 'createdAt' | 'eveHotel' | 'id' | 'informations'>
                & {
                    matchDateResponses: Array<(
                        Pick<MatchDateResponse, 'dinner' | 'hotel' | 'id' | 'informations' | 'lunch'>
                        & { matchDate: Pick<MatchDate, 'id'> }
                    )>
                }
            )>
        }
    )
    matchResponses: Array<MatchFormData>
    setMatchResponses: React.Dispatch<React.SetStateAction<MatchFormData[]>>
    indexMatch: number
    matchRefereeRoute: Pick<MatchRefereeRoute, 'id' | 'from' | 'to'>
}
const MatchForm = ({ requestMatch, matchResponses, setMatchResponses, indexMatch, matchRefereeRoute }: Props) => {
    const { match } = requestMatch;
    const matchResponse = matchResponses[indexMatch];

    const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const lens = R.lensPath([indexMatch, name]);
        setMatchResponses(R.set(lens, e.target.value, matchResponses));
    };

    const handleChangeChecked = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const lens = R.lensPath([indexMatch, name]);
        setMatchResponses(R.set(lens, e.target.checked, matchResponses));
    };

    return (
        <Box marginBottom={3}>
            <Card>
                <Box padding={2}>
                    <Typography variant="h6">
                        {match.title}
                    </Typography>
                    <Typography variant="body1">
                        {`Du ${DateTime.fromSQL(match.startDate).toLocaleString(DateTime.DATE_MED)} au ${DateTime.fromSQL(match.endDate).toLocaleString(DateTime.DATE_MED)}`}
                    </Typography>
                    <Typography variant="body1">
                        {`${match.address} ${match.zipCode} ${match.city}`}
                    </Typography>
                    <AppEditor initialValue={JSON.parse(match.informations)} readOnly />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={matchResponse.accepted}
                                onChange={handleChangeChecked('accepted')}
                                color="primary"
                            />
                        }
                        label="Je suis disponible pour participer"
                    />
                    <Collapse in={matchResponse.accepted} timeout="auto">
                        <Box paddingX={2}>
                            <KmFees prices={match.prices} matchResponse={matchResponse} matchRefereeRoute={matchRefereeRoute} />
                            <TextField fullWidth label="Informations" value={matchResponse.informations} onChange={handleChange('informations')} />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={matchResponse.eveHotel} disabled={!match.eveHotel}
                                        onChange={handleChangeChecked('eveHotel')} />
                                }
                                label="Hotel la veille"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={matchResponse.noKmsFees}
                                        onChange={handleChangeChecked('noKmsFees')} />
                                }
                                label="Sans frais kilométriques"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={matchResponse.freeHosting}
                                        onChange={handleChangeChecked('freeHosting')} />
                                }
                                label="Héberger gratuitement"
                            />
                            {match.matchDates.map((matchDate, indexMatchDate) => <MatchDateForm key={matchDate.id} matchDate={matchDate}
                                matchResponses={matchResponses} setMatchResponses={setMatchResponses} indexMatch={indexMatch} indexMatchDate={indexMatchDate} />)}
                        </Box>
                    </Collapse>
                </Box>
            </Card>
        </Box>
    );
};

MatchForm.whyDidYouRender = true

export default MatchForm;
export type { MatchFormData };
import React, { useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@material-ui/core';
import ButtonWithCircularProgress from 'components/ButtonWithCircularProgress';
import MatchForm, { MatchFormData } from './MatchForm';
import * as R from 'ramda';
import AppEditor from 'components/Editor';
import { useRespondToMatchRequestMutation, useRefereeRequestQuery } from 'generated/graphql';
import { formatName } from 'utils/formatName';

const Match = () => {
    const { token } = useParams();
    const [matchResponses, setMatchResponses] = useState<Array<MatchFormData>>([]);
    const [step, setStep] = useState(0);
    const [respondToMatchRequest, { loading: loadingMutation }] = useRespondToMatchRequestMutation({
        onError: error => {
            console.log(error);
        },
        onCompleted: _ => {
            setStep(2);
        }
    });

    const { data, loading } = useRefereeRequestQuery({
        variables: { token },
        onCompleted: data => {
            const responses: Array<MatchFormData> = data.refereeRequest.requestMatches.map(requestMatch => {
                const { lastResponse } = requestMatch;

                return {
                    matchId: requestMatch.match.id,
                    accepted: lastResponse ? lastResponse.accepted : false,
                    eveHotel: lastResponse ? lastResponse.eveHotel : false,
                    noKmsFees: lastResponse ? lastResponse.noKmsFees : false,
                    freeHosting: lastResponse ? lastResponse.freeHosting : false,
                    informations: lastResponse ? lastResponse.informations : '',
                    matchDates: requestMatch.match.matchDates.map(matchDate => {
                        const responseDate = lastResponse && lastResponse.matchDateResponses.find(r => r.matchDate.id === matchDate.id);

                        return {
                            matchDateId: matchDate.id,
                            accepted: responseDate ? true : false,
                            lunch: responseDate ? responseDate.lunch : false,
                            dinner: responseDate ? responseDate.dinner : false,
                            hotel: responseDate ? responseDate.hotel : false,
                            informations: responseDate ? responseDate.informations : '',
                        };
                    })
                };
            });
            setMatchResponses(responses);
        }
    });

    if (loading || !data)
        return null;

    const handleSubmit = () => {
        respondToMatchRequest({
            variables: {
                input: {
                    token: token,
                    responses: matchResponses.map(matchResponse => ({
                        ...matchResponse,
                        matchDates: matchResponse.matchDates.filter(e => e.accepted).map(e => R.omit(['accepted'], e))
                    }))
                }
            }
        });
    };

    const responded = data.refereeRequest.responded || data.refereeRequest.requestMatches.length === 0;

    const getStepContent = () => {
        switch (step) {
            case 0:
                return (
                    <Fragment>
                        <Box marginBottom={2}>
                            <AppEditor initialValue={JSON.parse(data.refereeRequest.message.message)} readOnly />
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Button variant="contained" color="primary" onClick={() => setStep(1)}>Commencer</Button>
                        </Box>
                    </Fragment>
                );
            case 1:
                return (
                    <Fragment>
                        <Box>
                            {data.refereeRequest.requestMatches.map((requestMatch, indexMatch) =>
                                <MatchForm key={requestMatch.id} requestMatch={requestMatch} indexMatch={indexMatch}
                                    matchResponses={matchResponses} setMatchResponses={setMatchResponses} matchRefereeRoute={requestMatch.matchRefereeRoute} />
                            )}
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <ButtonWithCircularProgress label="Valider" loading={loadingMutation} disabled={!canSubmit() || loadingMutation}
                                onClick={handleSubmit} />
                        </Box>
                    </Fragment>
                );
            case 2:
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography>Vos réponses ont bien été enregistrés.</Typography>
                    </Box>
                );
            default:
                return null;
        }
    };

    const canSubmit = () => {
        if (matchResponses === null)
            return false;

        for (const matchResponse of matchResponses) {
            if (matchResponse.accepted && matchResponse.matchDates.filter(matchDate => matchDate.accepted).length === 0)
                return false;
        }

        return true;
    };

    return (
        <Container maxWidth="md">
            <Box margin={2}>
                <Typography variant="h5" align="center">Appel à candidature - {formatName(data.refereeRequest.referee)}</Typography>
            </Box>
            {!responded && getStepContent()}
            {responded &&
                <Box display="flex" justifyContent="center">
                    <Typography>Vous avez déjà répondu</Typography>
                </Box>
            }
        </Container>
    );
};

export default Match;
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, IconButton, Toolbar, Hidden, Link, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu'
import { User, League, Department, Maybe } from 'generated/graphql';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        zIndex: 2,
        position: 'relative',
        '@media print ': {
            display: 'none'
        }
    },
    flexGrow: {
        flexGrow: 1
    }
}));

type Props = {
    onOpenNavBarMobile: () => void
    user: (
        Pick<User, 'id' | 'firstName' | 'lastName' | 'admin' | 'permissions' | 'email'>
        & {
            league: (
                Pick<League, 'id' | 'name' | 'logo'>
                & { departments: Array<Pick<Department, 'id' | 'name'>> }
            ), department?: Maybe<Pick<Department, 'id' | 'name' | 'logo'>>
        }
    ) | null
}
const TopBar = ({ onOpenNavBarMobile, user }: Props) => {
    const classes = useStyles();

    const title = user ? (user.department ? user.department.name : user.league.name) : "";

    return (
        <AppBar className={classes.root} color="primary">
            <Toolbar variant="dense" disableGutters>
                <div className={classes.flexGrow} >
                    <Hidden lgUp>
                        <IconButton color="inherit" onClick={onOpenNavBarMobile}>
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                </div>
                <Link color="inherit" to="/" variant="h5" underline="none" component={RouterLink}>{title}</Link>
                <div className={classes.flexGrow} />
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
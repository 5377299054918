import React, { useMemo, useState, useCallback } from 'react';
import { createEditor, Node } from 'slate';
import { Slate, Editable, withReact, RenderLeafProps, RenderElementProps } from 'slate-react';
import { Card, Box, makeStyles } from '@material-ui/core';
import Toolbar from './Toolbar';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    minHeight: {
        minHeight: "120px"
    },
}));

const Element = ({ attributes, children, element }: RenderElementProps) => {
    switch (element.type) {
        case 'left-align':
            return <p {...attributes} style={{ textAlign: "left" }}>{children}</p>
        case 'center-align':
            return <p {...attributes} style={{ textAlign: "center" }}>{children}</p>
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

type Props = {
    initialValue?: Array<Node>
    onChange?: (value: Array<Node>) => void
    readOnly?: boolean
}
const AppEditor = ({ initialValue, onChange, readOnly = false }: Props) => {
    const classes = useStyles();
    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const editor = useMemo(() => withReact(createEditor()), []);
    const [value, setValue] = useState<Array<Node>>(initialValue ? initialValue : [
        {
            type: 'paragraph',
            children: [{ text: '' }]
        }
    ]);

    const handleChange = (value: Array<Node>) => {
        if (onChange)
            onChange(value);
        setValue(value);
    };

    return (
        <Card variant="outlined" square>
            <Slate editor={editor} value={value} onChange={handleChange}>
                {!readOnly && <Toolbar />}
                <Box padding={1}>
                    <Editable
                        readOnly={readOnly}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        className={clsx({ [classes.minHeight]: !readOnly })}
                    />
                </Box>
            </Slate>
        </Card>
    );
};

export default AppEditor;

import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, Props>(theme => ({
    wrapper: {
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: props => props.size === "medium" ? -12 : -13,
        marginLeft: props => props.size === "medium" ? -12 : -13,
        width: props => props.fullWidth ? "100%" : "auto"
    }
}));

type Props = {
    variant?: "contained" | "text" | "outlined"
    color?: "inherit" | "primary" | "secondary" | "default"
    disabled: boolean
    loading: boolean
    
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    label: string
    type?: "button" | "submit"
    size?: "medium" | "large" | "small"
    fullWidth?: boolean
}
const ButtonWithCircularProgress = (props: Props) => {
    const { variant = "contained", color = "secondary", disabled, loading, onClick, label, type = "button", size = "medium", fullWidth = false } = props;
    const classes = useStyles(props);

    return (
        <div className={classes.wrapper}>
            <Button variant={variant} disabled={disabled} color={color}
                type={type} size={size} fullWidth={fullWidth} onClick={onClick}>
                {label}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
};

export default ButtonWithCircularProgress;
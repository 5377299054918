import React, { useState, useContext } from 'react';
import { UserContext } from 'context';
import {
    Container, Typography, Box, Card,
    TextField
} from '@material-ui/core';
import ButtonWithCircularProgress from 'components/ButtonWithCircularProgress';
import { useUpdateEmailMutation } from 'generated/graphql';
import { useSnackbar } from 'notistack';

const Account = () => {
    const user = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState<string>(user.email);
    const [emailFoundError, setEmailFoundError] = useState(false);
    const [updateEmail, { loading }] = useUpdateEmailMutation({
        onCompleted: () => enqueueSnackbar("Mail mis à jour !", { variant: "success" }),
        onError: error => {
            if (error.message.includes('email found'))
                setEmailFoundError(true);
        }
    });

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        updateEmail({ variables: { input: { email: email } } });
    };

    return (
        <Container maxWidth="md">
            <Box marginBottom={4}>
                <Box marginY={4}>
                    <Box marginBottom={2}>
                        <Typography variant="h5" align="center">Mon compte</Typography>
                    </Box>
                </Box>
                <Card variant="outlined" square>
                    <Box padding={2} display="flex" flexDirection="column" alignItems="center">
                        <TextField label="Adresse mail" name="email" onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailFoundError(false);
                        }} required value={email} error={emailFoundError} helperText={emailFoundError && "Cette adresse est déjà associé à un utilisateur"} />
                        <Box marginTop={2}>
                            <ButtonWithCircularProgress loading={loading} label="Mettre à jour" disabled={email === "" || loading} onClick={handleSubmit} />
                        </Box>
                    </Box>
                </Card>
            </Box>
        </Container>
    );
};

export default Account;
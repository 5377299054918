
type FormatNameType = ({ firstName, lastName }: { firstName: string, lastName: string }) => string
const formatName: FormatNameType = ({ firstName, lastName }) => {
    return `${lastName.toUpperCase()} ${firstName.charAt(0).toUpperCase() + firstName.slice(1)}`;
};

type FormatFirstNameType = ({ firstName }: { firstName: string }) => string
const formatFirstName: FormatFirstNameType = ({ firstName }) => {
    return `${firstName.charAt(0).toUpperCase() + firstName.slice(1)}`;
};

type FormatLastNameType = ({ lastName }: { lastName: string }) => string
const formatLastName: FormatLastNameType = ({ lastName }) => {
    return `${lastName.toUpperCase()}`;
};

export { formatName, formatFirstName, formatLastName };
